import { useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

import { useLogin } from '../../hooks/useLogin';

import { FaExclamationCircle } from "react-icons/fa";

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, isPending } = useLogin();

  const handleLogin = (e) => {
    e.preventDefault();
    login(email, password);
  }

  return (
    <div className="body">
      <form onSubmit={handleLogin} className='card'>
        <h2>Login</h2>
        {error && <div className='error-message'><FaExclamationCircle className='icon' />{error}</div>}
        <label>
          <span>Email</span>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            value={email}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            value={password}
          />
        </label>
        <Link className='forgotpassword' to="/forgotpassword">Forgot Password?</Link>
        {!isPending && <button className='btn' type='submit'>Login</button>}
        {isPending && <button className='btn' type='submit' disabled>please wait...</button>}
      </form>
    </div>
  )
}
