import { projectFirestore } from '../../firebase/config';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import './Course.css';

export default function Course() {

  const { id } = useParams();

  const [course, setCourse] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsPending(true);

    projectFirestore.collection('courses').doc(id).get().then((doc) => {
      if (doc.exists) {
        setIsPending(false);
        setCourse(doc.data());
      } else {
        setIsPending(false);
        setError('Could not find that course');
      }
    }).catch(error => {
      setIsPending(false);
      setError(error.message);
    });
  }, [id]);

  return (
    <div className='container'>
      {error && (
        <div className='error'>
          <h3>Oops! Error Occurred!</h3>
          <p>{error}</p>
        </div>
      )}
      {course && (
        <div className="course">
          <div className="head">
            {/* <img src={course.image} className="course-image" alt="Course" /> */}
            <iframe className="course-image" src={`https://www.youtube-nocookie.com/embed/${course.trailerId}`} title={course.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className="col">
              <h1>{course.title}</h1>
              <p>{course.description}</p>
              {course.status === "presale" ? <div className="price"><del>{course.currency}{course.price}</del> <br /> {course.preprice === 0 ? 'FREE' : course.currency + course.preprice}</div> : <div className="price">{course.price === 0 ? 'FREE' : course.currency + course.price}</div>}
              <a href={course.buylink} target="_blank" rel="noopener noreferrer"><button className='btn'>Enroll Now</button></a>
            </div>
          </div>

          <div className="info-section">
            <h1>What's Included</h1>
            <div className="data-summary">
              <div className="item">
                <div className="meta"><svg class="svg-inline--fa fa-video fa-w-18 icon me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="video" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="var(--grey-text)" d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"></path></svg> Content</div>
                <div className="data">{course.modules.length} Modules</div>
              </div>
              <div className="vdivider"></div>
              <div className="item">
                <div className="meta"><svg class="svg-inline--fa fa-clock fa-w-16 icon me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="var(--grey-text)" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> Duration</div>
                <div className="data">{course.hours} Hours</div>
              </div>
              <div className="vdivider"></div>
              <div className="item">
                <div className="meta"><svg class="svg-inline--fa fa-user-circle fa-w-16 icon me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" data-fa-i2svg=""><path fill="var(--grey-text)" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg> Access</div>
                <div className="data">Lifetime</div>
              </div>
            </div>
          </div>

          <div className="info-section">
            <h1>What Will You Learn</h1>
            <p>{course.wwyldescription}</p>
            <div className='ul'>
              {course.wwylpoints.map(
                (p) => <li key={p}><svg className="check-mark" data-icon="check" role="img" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="var(--primary)" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg> <span>{p}</span></li>
              )}
            </div>
          </div>
          <center>
            <a href={course.buylink} target="_blank" rel="noopener noreferrer"><button className='btn'>Enroll Now</button></a>
          </center>
          <div className="info-section">
            <h1>Course Modules</h1>
            {course.modules.map(
              (m) => <div key={m}><button className="collapsible"> {m.title} </button>
                <div className="content">
                  <p>{m.description}</p>
                  <div className='ul'>{m.lessons.map((lesson) => <li key={lesson}>{lesson.title} <span className='duration'>{lesson.duration}</span> </li>)}</div>
                </div></div>
            )}
          </div>
          <center>
            <a href={course.buylink} target="_blank" rel="noopener noreferrer"><button className='btn'>Enroll Now</button></a>
          </center>
          <div className="info-section">
            <h1>Who Is This Course For</h1>
            <p>{course.witcf}</p>
          </div>
          <div className="info-section">
            <h1>Meet The Tutor</h1>
            <div className="head">
              <img src={course.authorimage} className="author-image" alt="Author" />
              <div className="col">
                <h2>{course.authorname}</h2>
                <p>Hey there, my name is Kamal and since a young age I've had an obsession for nearly anything tech-related. I've been coding since about the age of 15 (5 years now) and work as a full-stack flutter developer.</p>
                <br />
                <p>Currently I'm running software development company known as <b>Panara Studios Private Limited</b>.</p>

                <p>Want to hire me for your flutter project?
                  You can email me at <a href="mailto:info@panarastudios.in"><b>info@panarastudios.in</b></a> </p>
                <br />
                <h3> Know someone who may find the course useful?</h3>
                <p> Please help me spread the word!
                </p>
              </div>
            </div>
          </div>
          <center>
            <a href={course.buylink} target="_blank" rel="noopener noreferrer"><button className='btn'>Enroll Now</button></a>
          </center>
          <Helmet>
            <script type='text/javascript'>
              {`
                var coll = document.getElementsByClassName("collapsible");
                var i;

                for (i = 0; i < coll.length; i++) {
                  coll[i].addEventListener("click", function() {
                    this.classList.toggle("active");
                    var content = this.nextElementSibling;
                    if (content.style.maxHeight){
                      content.style.maxHeight = null;
                    } else {
                      content.style.maxHeight = content.scrollHeight + "px";
                    } 
                  });
                }
              `}
            </script>
          </Helmet>
        </div>
      )}
      {isPending && (
        <div className="loading">Loading...</div>
      )}
    </div>
  )
}
