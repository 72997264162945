import { projectFirestore } from '../../firebase/config';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SkeletonCourseCard from '../../skeletons/SkeletonCourseCard';
import './Home.css';

export default function Home() {

  const [courses, setCourses] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsPending(true);
    projectFirestore.collection('courses').get().then((snapshot) => {
      if (snapshot.empty) {
        setIsPending(false);
        setError("No Course Found in Course Library, Or maybe a network error, please reload this page!");
      } else {
        let results = [];
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() });
        });
        setIsPending(false);
        setCourses(results);
      }
    }).catch(error => {
      setIsPending(false);
      setError(error.message);
    });
  }, []);

  return (
    <div className='container'>
      <h1 className="heading">COURSE LIBRARY</h1>
      <p className="description">All courses provided by Panara Studios, including upcoming courses, login to access your purchased courses.</p>
      <hr />
      {error && (
        <div className='error'>
          <h3>Oops! Error Occurred!</h3>
          <p>{error}</p>
        </div>
      )}
      <div className="course-list">
        {courses && courses.map(course => (
          <Link key={course.id} className='link' to={`/courses/${course.id}`}>
            <div className="card">
              {course.status === "presale" && <div className="corner-ribbon top-left sticky shadow">PRE-SALE</div>}
              <img
                className='course-image'
                src={course.image}
                alt="Course" />
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <div className="bottom-bar">
                <div className="author">
                  <img className="author-image" src={course.authorimage} alt='' />

                  <div className="author-name">{course.authorname}</div>
                </div>
                {course.status === "presale" ? <div className="price"><del>{course.currency}{course.price}</del> <br /> {course.preprice === 0 ? 'FREE' : course.currency + course.preprice}</div> : <div className="price">{course.price === 0 ? 'FREE' : course.currency + course.price}</div>}
              </div>
            </div>
          </Link>
        ))}

        {isPending && [1, 2, 3].map((n) => <SkeletonCourseCard key={n} />)}
      </div>
    </div>
  )
}
