import './style.css';
import { useState } from 'react';

import { useForgotPassword } from '../../hooks/useForgotPassword';

import { FaExclamationCircle } from "react-icons/fa";

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { forgotPassword, error, isPending, isSent } = useForgotPassword();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  return (
    <div className='body'>
       <form onSubmit={handleForgotPassword} className='card'>
        <h2>Forgot Password?</h2>
        {error && <div className='error-message'><FaExclamationCircle className='icon' />{error}</div>}
        {isSent && <div className='success-message'>Email sent, check your inbox!</div> }
        <label className="notice">
          Enter your email address linked with your account, we will send you password reset link on your email.
        </label>
        <label>
          <span>Email</span>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            value={email}
          />
        </label>
        {!isPending && <button className='btn' type='submit'>Submit</button>}
        {isPending && <button className='btn' type='submit' disabled>please wait...</button>}
        </form>
    </div>
  )
}
