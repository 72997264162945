import { BrowserRouter, Route, Switch, Link, Redirect } from 'react-router-dom';

import './App.css';

import Home from './pages/home/Home';
import Course from './pages/home/Course';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ForgotPassword from './pages/auth/ForgotPassword';
import Terms from './pages/footer/Terms';
import Privacy from './pages/footer/Privacy';
import Payment from './pages/footer/Payment';

import { useLogout } from './hooks/useLogout';

import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { useAuthContext } from './hooks/useAuthContext';

function App() {

  const handleHamMenu = () => {
    const navbarLinks = document.getElementsByClassName('navbarlinks')[0];
    navbarLinks.classList.toggle('active');
  };

  const { authIsReady, user } = useAuthContext();

  const { logout } = useLogout();

  return (
    <div className="App">
      {authIsReady && (


        <BrowserRouter>
          <nav>
            <Link style={{ textDecoration: 'none', color: '#fff' }} to='/'>
              <h1>Panara Studios</h1>
            </Link>
            <div href="#" onClick={handleHamMenu} className='toggle-button'>
              <div className='bar'></div>
              <div className='bar'></div>
              <div className='bar'></div>
            </div>
            <div className="navbarlinks">
              {!user && (
                <>
                  <Link className='navlinks' to="/login">Login</Link>
                  <Link className='navlinks' to="/signup">Signup</Link>
                </>
              )}
              {user && (
                <>
                  <div className='userlogin-name'>Hey, {user.displayName}</div>
                  <Link className='navlinks' onClick={logout}>Logout</Link>
                </>
              )}
            </div>
          </nav>

          <Switch >
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/login">
              {user && <Redirect to='/' />}
              {!user &&
                <Login />
              }
            </Route>
            <Route path="/signup">
              {user && <Redirect to='/' />}
              {!user &&
                <Signup />
              }
            </Route>
            <Route path="/forgotpassword">
              {user && <Redirect to='/' />}
              {!user &&
                <ForgotPassword />
              }
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/payment">
              <Payment />
            </Route>
            <Route path="/courses/:id">
              <Course />
            </Route>
          </Switch>

          <footer>
            <ul className="list">
              <li>
                <a className='link' href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a className='link' href="/terms">Terms of Use</a>
              </li>
              <li>
                <a className='link' href="/payment">Payment & Refund Policy</a>
              </li>
            </ul>
            <div className="social">
              <a href="https://www.youtube.com/channel/UC3setbO9Ennr3WhMCvH4XvA" rel="noreferrer" target='_blank'>
                <FaYoutube />
              </a>
              <a href="https://twitter.com/PanaraStudios" rel="noreferrer" target='_blank'>
                <FaTwitter />
              </a>
              <a href="https://www.instagram.com/panarastudios/" rel="noreferrer" target='_blank'>
                <FaInstagram />
              </a>
            </div>
            <p className="copyright">
              &copy; Copyrights <b>Panara Studios</b>  2022. All Rights Reserved
            </p>
          </footer>

        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
