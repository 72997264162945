import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC_-DEsiQZb6vdTzbS_STB2jjsMCiL9uSM",
    authDomain: "panara-studios.firebaseapp.com",
    databaseURL: "https://panara-studios-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "panara-studios",
    storageBucket: "panara-studios.appspot.com",
    messagingSenderId: "431134314806",
    appId: "1:431134314806:web:0d6af3b28877237bade397",
    measurementId: "G-GJTH7ZGSQM"
  };

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

export { projectFirestore, projectAuth };