import './style.css';

export default function Payment() {
  return (
    <div className='pagestyle'>

      <h1>Payment Policy</h1>

      <p> We accept the payment from Credit or Debit Cards via Stripe Payments.</p>

      <h1>Refund Policy</h1>

      <p>We want You to be happy with our Platform.</p>
      <ol>
        <li> In general, all Students purchasing courses are entitled to a seven (7) days refund from the date of purchase.</li>
        <li> If Student has enrolled into Pre-sale and requests refund before course release will get 100% refund.</li>
        <li> We reserves the right to refuse refunds to Students who abuse this Refund Policy. Examples of abuse include, but are not limited to, requesting refunds for multiple courses or requesting refunds in consecutive months.</li>
      </ol>

      <h3>Contact us at <a href="mailto:courses@panarastudios.in" rel="noreferrer" target="_blank" >courses@panarastudios.in</a> for refund request.</h3>

    </div>
  )
}
