import { useState, useEffect } from 'react';
import { projectAuth } from '../firebase/config';

export const useForgotPassword = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const forgotPassword = async (email) => {
    setError(null);
    setIsPending(true);
    setIsSent(false);

    try {
      // send mail with password reset instructions to user.
      await projectAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          setIsSent(true);
          if (!isCancelled) {
            setIsPending(false);
            setError(null);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setError(error.message);
            setIsPending(false);
          }
        });
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => () => setIsCancelled(true), []);

  return { forgotPassword, isPending, error, isSent };
};