import './style.css';

export default function Privacy() {
  return (
    <div className='pagestyle'>
      <h1>Privacy Policy</h1>
      <p>This Privacy Policy governs the manner in which the School collects, uses, maintains and discloses information collected from users (each, a “Student”) of the School. This Privacy Policy applies to the School and all Courses offered by the School.</p>

      <h2> Personal identification information </h2>

      <p> We may collect personal identification information from Students in a variety of ways, including, but not limited to, when Students enroll in the School or a Course within the School, subscribe to a newsletter, and in connection with other activities, services, features, or resources we make available in our School. Students may visit the School anonymously. We will collect personal identification information from Students only if they voluntarily submit such information to us. Students can refuse to supply personal identification information but doing so may prevent them from engaging in certain School related activities.</p>

      <h2>How we use collected information</h2>

      <p>The School may collect and use Student’s personal identification information for the following purposes:</p>
      <ul>
        <li> To improve customer service
          Information you provide helps us respond to your customer service requests and support needs more efficiently.</li><br />
        <li> To personalize user experience
          We may use information in the aggregate to understand how our Students as a group use the services and resources provided in our School.</li><br />
        <li> To send periodic emails
          We may use Student email addresses to send Students information and updates pertaining to their order. Student email addresses may also be used to respond to Student inquiries, questions, or other requests.</li><br />
      </ul>

      <h2>Sharing your personal information</h2>

      <p>We do not sell, trade, or rent Student personal identification information to others.</p>

      <h2>Changes to this Privacy Policy</h2>

      <p>The School has the discretion to update this Privacy Policy at any time. We encourage Students to frequently check this page for any changes. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.</p>

      <h2>Your acceptance of these terms</h2>

      <p>By enrolling in the School, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not enroll in the School. Your continued enrollment in the School following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>
    </div>
  )
}
