import React from 'react'
import Shimmer from './Shimmer';
import './Skeleton.css';
import SkeletonElement from './SkeletonElement';

export default function SkeletonCourseCard() {
    return (
        <div className='skeleton-wrapper'>
            <SkeletonElement type="thumbnail" />
            <div className="skeleton-course-card">
                <SkeletonElement type="title" />
                <SkeletonElement type="text" />
                <SkeletonElement type="text" />
                <SkeletonElement type="text" />
                <div className="row">
                    <SkeletonElement type="avatar" />
                    <SkeletonElement type="title" />
                </div>
            </div>
            <Shimmer />
        </div>
    )
}
