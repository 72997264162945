import { useState } from 'react';
import { useSignup } from '../../hooks/useSignup';

import './style.css';
import { FaExclamationCircle } from "react-icons/fa";


export default function Signup() {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { signup, isPending, error } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(username, email, password);
  };

  return (
    <div className='body'>
      <form onSubmit={handleSubmit} className='card'>
        <h2>Sign Up</h2>
        {error && <div className='error-message'><FaExclamationCircle className='icon' />{error}</div>}
        <label>
          <span>Username</span>
          <input
            required
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            value={username}
          />
        </label>

        <label>
          <span>Email</span>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            value={email}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            value={password}
          />
        </label>
        <label className='agreement'>
          By Signing up you agree to our <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </label>
        {!isPending && <button className='btn' type='submit'>Signup</button>}
        {isPending && <button className='btn' type='submit' disabled>please wait...</button>}
        
      </form>
    </div>
  )
}
